<template>
  <div class="mobileGateway">
    <mHead :name="'超级主机'" :add="'/mobile/ProGateway'" :adds="'/mobile/ProGatewayParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="title">超级主机</div>
        <div class="titles">一个主机，改变一切</div>
        <div class="text">强大的系统硬件</div>
        <div class="text texts">集不同设备和功能于一身</div>
        <div class="text texts">更有优秀的配套软硬件解决方案</div>
        <div class="text texts">让您拥有舒适</div>
        <div class="text texts">自然的入住体验</div>
      </div>
    </div>
    <div class="advantages">
      <div class="ad">
        <div class="adTit">覆盖广</div>
        <div class="adTxt">轻松覆盖500㎡</div>
      </div>
      <div class="ad">
        <div class="adTit">稳定可靠</div>
        <div class="adTxt">自研技术，不惧干扰</div>
      </div>
      <div class="ad">
        <div class="adTit">低功耗</div>
        <div class="adTxt">减少开支，每月仅需一度电</div>
      </div>
      <div class="ad">
        <div class="adTit">隐私保护</div>
        <div class="adTxt">保障用户隐私安全</div>
      </div>
    </div>
    <!-- 超级主机 -->
    <div class="sec1">
      <div class="sec1Tit">强大到不可想象的超级主机</div>
      <div class="sec1Text">基于我们为此打造的强大简洁的主机系统和互联技术，</div>
      <div class="sec1Text sec1Texts">使用户可以通过按键，触摸屏，</div>
      <div class="sec1Text sec1Texts">语音和微信小程序等多种交互方式实现对屋内照明、空调、</div>
      <div class="sec1Text sec1Texts">电动窗帘和其他智能设备系统的智能化集中控制。</div>
      <div class="imgBox">
        <div class="img">
          <div class="imgTxt">灯光控制</div>
        </div>
        <div class="img">
          <div class="imgTxt">空调控制</div>
        </div>
        <div class="img">
          <div class="imgTxt">窗帘控制</div>
        </div>
        <div class="img">
          <div class="imgTxt">音乐控制</div>
        </div>
      </div>
    </div>
    <!-- 体验 -->
    <div class="sec2">
      <div class="sec2Tit">前所未有的人性化智能互联体验</div>
      <div class="sec2Text">基于主机内置的可扩展系统以及强大的云计算和</div>
      <div class="sec2Text sec2Texts">人工智能技术为支撑，使配置和安装的效率提高的史无前例。</div>
      <div class="sec2Text sec2Texts">同时，让用户的体验更加简单舒适。</div>
      <div class="box">
        <div class="sec2Box">
          <img src="../../assets/mobile/product/gateway5.png" class="se2Img" />
          <div class="boxTit">一切，出口即成</div>
          <div class="boxText">唤醒智能助理，</div>
          <div class="boxText boxTexts">无需拿出手机即可实现对智能设备的控制</div>
        </div>
        <div class="sec2Box">
          <img src="../../assets/mobile/product/gateway6.png" class="se2Img" />
          <div class="boxTit">无线，无限</div>
          <div class="boxText">使用我们自研的高性能低延迟的无线组网方案，</div>
          <div class="boxText boxTexts">传输快，距离远，稳定性高，抗干扰能力强</div>
        </div>
        <div class="sec2Box">
          <img src="../../assets/mobile/product/gateway7.png" class="se2Img" />
          <div class="boxTit">一切，由你来定</div>
          <div class="boxText">基于我们自己研发的强大的主机系统，</div>
          <div class="boxText boxTexts">实现完全定制化</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  }
};
</script>
<style scoped lang="less">
.mobileGateway {
  background-color: #fff;
  .banner {
    width: 100%;
    height: 8rem;
    background-image: url("../../assets/mobile/product/gatewayBanner.png");
    background-size: 100% 100%;
    .bannerBox {
      color: #fff;
      padding-left: 0.3rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 300;
      text-align: left;
      .title {
        padding-top: 1.29rem;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
      }
      .titles {
        margin-top: 0.23rem;
        font-size: 0.3rem;
        line-height: 0.3rem;
        font-weight: 400;
      }
      .text {
        margin-top: 0.76rem;
      }
      .texts {
        margin-top: 0.16rem;
      }
    }
  }
  .advantages {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .ad {
      width: 50%;
      padding: 0.2rem 0;
      text-align: center;
      border-right: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: bold;
      color: #ff9000;
      &:nth-of-type(2n) {
        border-right: 0px;
      }
      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-bottom: 0px;
      }
      .adTxt {
        margin-top: 0.1rem;
        font-weight: 300;
        color: #666;
      }
    }
  }
  .sec1 {
    width: 100%;
    background-color: #f8f8f8;
    color: #fff;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: bold;
    padding: 0.8rem 0.3rem;
    .sec1Tit {
      font-size: 0.36rem;
      color: #333333;
    }
    .sec1Text {
      margin-top: 0.4rem;
      font-weight: 400;
      color: #333333;
    }
    .sec1Texts {
      margin-top: 0.16rem;
    }
    .img {
      width: 100%;
      height: 4rem;
      margin-top: 0.3rem;
      background-image: url(../../assets/mobile/product/gateway1.png);
      background-size: 100% 100%;

      &:nth-of-type(2) {
        background-image: url(../../assets/mobile/product/gateway2.png) !important;
        background-size: 100% 100%;
      }
      &:nth-of-type(3) {
        background-image: url(../../assets/mobile/product/gateway3.png);
        background-size: 100% 100%;
      }
      &:nth-of-type(4) {
        background-image: url(../../assets/mobile/product/gateway4.png);
        background-size: 100% 100%;
      }
      .imgTxt {
        text-align: left;
        padding-left: 0.3rem;
        padding-top: 3.49rem;
      }
    }
  }
  .sec2 {
    width: 100%;
    background-color: #fff;
    color: #333;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: bold;
    padding: 0.8rem 0.3rem;
    .sec2Tit {
      font-size: 0.36rem;
    }
    .sec2Text {
      margin-top: 0.39rem;
      font-weight: 400;
      color: #333333;
    }
    .sec2Texts {
      margin-top: 0.16rem;
    }
    .sec2Box {
      width: 100%;
      padding: 0.3rem;
      background-color: #f8f8f8;
      text-align: left;
      color: #333333;
      margin-bottom: 0.3rem;  
      &:first-of-type{
        margin-top: 0.66rem;
      }  
      &:last-of-type {
        margin-bottom: 0px;
      }
      .se2Img {
        width: 100%;
        height: 6rem;
      }
      .boxTit {
        margin-top: 0.25rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 800;
      }
      .boxText {
        margin-top: 0.29rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
      }
      .boxTexts {
        margin-top: 0.16rem;
      }
    }
  }
}
</style>


